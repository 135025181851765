import { useEffect, useState } from 'react';

/**
 * It's a copy of the same hook from frontend app.
 * I guess could be moved to SDS
 */

/**
 * Used if you need to run effect each render.
 * It's an edge case and might affect to performance, but I didn't find it after tests.
 * Used on marketplace page
 */
// type Options = {
//     cleanEffectDependencies?: boolean;
// };

/**
 * useStickyPositionHeight - used for calculating top position for sticky element.
 * You might use it if your element has position sticky, and it should stop before some element with dynamic height.
 * Usually in this project it's a header;
 * const top = useStickyPositionHeight('header');
 * @param {string} elQuery - string for element
 * @param {Options} options - object with options, see TS type.
 * @return number - number in px from top of viewport to bottom of the found element
 */
export const useStickyPositionHeight = (elQuery, options) => {
  const { cleanEffectDependencies } = options || {};
  const [topState, setTopState] = useState(0);
  useEffect(
    () => {
      const onResizeHandler = () => {
        const header = document.querySelector(elQuery);
        if (!header) return;
        // https://developer.mozilla.org/en-US/docs/Web/API/Element/getBoundingClientRect
        const { height } = header.getBoundingClientRect();
        setTopState(height);
      };

      onResizeHandler();
      window.addEventListener(`resize`, onResizeHandler);

      return () => window.removeEventListener(`resize`, onResizeHandler);
    },
    // Check the description of cleanEffectDependencies above
    // eslint-disable-next-line react-hooks/exhaustive-deps
    cleanEffectDependencies ? undefined : [elQuery]
  );
  return topState;
};
